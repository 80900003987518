import * as React from "react"

import "./image.scss"

const Image = (props) => {
    return (
        <>
            <div className="image-wrap">
                <img className="image" src={props.src} />
            </div>
        </>
    );
};

export default Image
