import * as React from "react"

import "./heading.scss"

const Heading = (props) => {
    return (
        <>
            <div className="heading-wrap">
                <div className="heading">
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default Heading
