import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Image from "../components/image/image"
import Text from "../components/text/text"
import Heading from "../components/heading/heading"

import parents from "../assets/parents.png"

import "./index.scss"

const IndexPage = () => {
	const ladybaby = <><span className="turquoise">Lady</span><span className="pink">&Baby</span></>;

	return (
		<Layout theme="turquoise">
			<div className="highlight-wrap">
				<div className="highlight kindergarten">
					<div className="highlight-text">
						<div className="highlight-title">
							<h1><span className="turquoise">Lady</span><span className="pink">&Baby</span></h1>
						</div>

						<div className="highlight-description">
							<p>це сім’я дитячих центрів і ліцензійний садочків повного дня</p>
						</div>
					</div>

					<div className="highlight-image"></div>
				</div>
			</div>

			<Text>
				<center>
					<p>
						{ladybaby}<br />
						— це сім’я дитячих центрів і ліцензійний садочків повного дня, яка знаходиться у передмісті Києва — Софіївській Борщагівці.<br />
						Наші садочки розміщено у приватному секторі, а отже кожний садочок має власну огороджену простору зелену територію з облаштованими ігровими зонами.
					</p>
					<p>
						{ladybaby}<br />
						— це простір, де в дитині будь-якого віку вбачають особистість, а отже ставляться до неї з повагою та з розумінням до її вікових особливостей і потреб.
					</p>
				</center>
			</Text>

			<Image src={parents} />
	
			<Heading>
				<center>
					<h2>Дорогий Дорослий</h2>
				</center>
			</Heading>

			<Text>
				<center>
					<p>
						що б не відбувалось, та життя триває.<br />
						Знову і знову, знову і знову…<br />
						За своїми законами, в різних формах та способах.<br />
						І щоразу, написання своєї Книги Життя розпочинає кожна новонароджена дитина.<br />
						Вітальність, спрага до життя, бажання відкривати і досліджувати, насолоджуватись і бути, прагнення обіймати і говорити — це все про бажання жити, з радістю та насолодою.<br />
						Наша основна задача, ціль і мета — щоденно допомагати дитині ставати такою, яка здатна гнучко мислити, аналізувати і критично осмислювати навколишню дійсність. Зростати особистістю, яка сповнена любові, віри і поваги до себе та інших. Яка є здоровою і має напрацьовані кейси з компетентностей у різних діяльностях. Яка є щасливою і стійкою до викликів життя.<br />
						І то нам краще вдасться, дорогий Дорослий, в тандемі з тобою.
					</p>
				</center>
			</Text>

			{/* 
			<div className="heading-wrap">
				<div className="heading">
					<center>
						<h2>Дорогий Дорослий</h2>
					</center>
				</div>
			</div>

			<div className="text-wrap">
				<div className="text">
					<center>
						<p>
							Що б не відбувалось, та життя триває.<br/>
							Знову і знову, знову і знову…<br/>
							За своїми законами, в різних формах та способах.<br/>
							І щоразу, написання своєї Книги Життя розпочинає кожна новонароджена дитина.<br/>
							Вітальність, спрага до життя, бажання відкривати і досліджувати, насолоджуватись і бути, прагнення обіймати і говорити — це все про бажання жити, з радістю та насолодою.<br/>
							Наша основна задача, ціль і мета — щоденно допомагати дитині ставати такою, яка здатна гнучко мислити, аналізувати і критично осмислювати навколишню дійсність. Зростати особистістю, яка сповнена любові, віри і поваги до себе та інших. Яка є здоровою і має напрацьовані кейси з компетентностей у різних діяльностях. Яка є щасливою і стійкою до викликів життя.<br/>
							І то нам краще вдасться, дорогий Дорослий, в тандемі з тобою.
						</p>
					</center>
				</div>
			</div>
			*/}

		</Layout>
	);
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head = () => <Seo title="Home" />

export default IndexPage
